<template>
  <div>
    <create-project-section :isInsideFolder="isInsideFolder" :folderId="currentFolderId" />
    <dashboard-navigation-bar :folderPath="currentFolderPath" @navigateToFolder="updateFolderPathFromNav"
      @filterContent="handleFilterContent" :isInsideFolder="isInsideFolder" @sortContent="sortContent"/>
    <project-list-dashboard @folderChange="openFolder" :projectList="projectList" :folderList="folderList"
      :userHasProjects="userHasProjects" @loadMoreCompleteProjects="fetchCompleteProjects"
      :isLoadingCompleteProjects="isLoadingCompleteProjects" :isLoadingInProgressProjects="isLoadingInProgressProjects"
      :filterType="filterType" :noMoreProjectsToLoad="isInsideFolder ? noMoreProjectsToLoadFolder : noMoreProjectsToLoad"
      :isInsideFolder="isInsideFolder" :userHasFolders="userHasFolders" />

  </div>
</template>

<script>
import CreateProjectSection from "./../components/Dashboard/CreateProjectSection";
import ProjectListDashboard from "./../components/Dashboard/ProjectListDashboard";
import DashboardNavigationBar from "./../components/Dashboard/DashboardNavigationBar.vue";

export default {
  components: {
    CreateProjectSection,
    ProjectListDashboard,
    DashboardNavigationBar
  },
  data() {
    return {
      currentFolderPath: ['Home'], // Initialize with "Home" as the root folder
      filterType: 'all', // all | projects | folders

      completedProjectsLimit: 24, // Completed projects are 'closed' projects
      completedProjectsPage: 1,
      isLoadingCompleteProjects: false,

      isLoadingInProgressProjects: false,
      currentFolderId: '',

      sortBy: 'date'

    };
  },
  methods: {
    async fetchCompleteProjects(forceRefetch = false) {
      // This approach will work fine if we dont have folders inside folders
      // If we eventually have this feature we should object array that will hold the projects for each folder / sub-folder
      // So we will be able to represent a somewhat of a file system
      this.isLoadingCompleteProjects = true;
      const isInsideFolder = this.currentFolderPath.length > 1;
      const limit = this.completedProjectsLimit;
      const skip = this.completedProjectList.length;
      const sort = this.$store.getters['project/getProjectSortingStatus']
      const projectsStatus = 'closed'

      if (isInsideFolder) {
        const folderId = this.$store.getters['folder/getActiveFolder']._id
        this.$store.dispatch('folder/retrieveProjectsFromFolder', { folderId, limit, skip, appendToProjects: true, projectsStatus,sort })
          .then(() => {

          }).catch((err) => {
            console.error("Error while fetching complete projects for folder: ", err)
          }).finally(() => {
            this.isLoadingCompleteProjects = false;
          })
      }

      if(!isInsideFolder || forceRefetch) {
        await this.$store.dispatch("project/retrieveProjectsByClientId", { limit, skip, appendToProjects: true,  projectsStatus,sort })
          .then(() => {

          }).catch((err) => {
            console.error("Error while pagination, retrieving projects: ", err)
          }).finally(() => {
            this.isLoadingCompleteProjects = false;
          })

      }
    },

    updateFolderPathFromNav(newPath) {
      this.currentFolderPath = newPath; // Update the path based on navbar clicks
    },
    openFolder(folder) {
      this.currentFolderId = folder[0]._id
      this.clearFolderContents(); // Clear current folder contents to load new ones
      const activeFolder = this.$store.getters['folder/getActiveFolder'];
      if (activeFolder._id === folder[0]._id) {
        this.currentFolderPath = ['Home', ...folder]; // Update the path when navigating into folders
        this.filterType = 'all'
        return;
      }
      this.$store.commit("loader/SET_LOADER", {})
      this.$store.commit('folder/SET_ACTIVE_FOLDER', folder)
      this.$store.commit("folder/SET_MAX_CLOSED_PROJECTS_REACHED", false)
      this.filterType = 'all'
      const folderId = folder[0]._id;
      this.$store.dispatch("folder/retrieveFolderByClientId", { folderId, limit: this.completedProjectsLimit })
        .then(() => {
          this.currentFolderPath = ['Home', ...folder]; // Update the path when navigating into folders
        })
        .catch((error) => {
          console.error("Error fetching folder contents:", error);
        })
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    },
    clearFolderContents() {

    },
    handleFilterContent(filterType) {
      this.filterType = filterType; // Update filter type based on nav bar selection
    },
    async resetAndRefetchCompleteProjects(){
      this.$store.commit('project/RESET_COMPLETE_PROJECT_LIST');
      this.$store.commit('folder/RESET_COMPLETE_PROJECT_LIST');
      await this.fetchCompleteProjects(true);
    },
    sortContent(sort) {
      if(sort === this.sortBy) return;
      this.sortBy = sort;
      this.$store.commit("project/SET_PROJECT_SORTING",sort);
      this.resetAndRefetchCompleteProjects();
    },
  },
  computed: {
    folderList() {
      if (this.currentFolderPath.length !== 1 || this.filterType === 'projects') return [];
      const folderList = this.$store.getters["folder/getFolderList"];
      let sortedFolderList = folderList;
      switch (this.sortBy) {
        case 'alph':
          sortedFolderList = folderList.slice().sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
          break;
          case 'asc' :
          sortedFolderList = folderList.slice().sort((a, b) => {
            if (a.createdAt < b.createdAt) return -1;
            if (a.createdAt > b.createdAt) return 1;
            return 0;
          });
          break;
        case 'dec':
        case 'date':
        default:
        sortedFolderList = folderList.slice().sort((a, b) => {
            if (a.createdAt > b.createdAt) return -1;
            if (a.createdAt < b.createdAt) return 1;
            return 0;
          });
          break;
      }
      // const sortedFolderList = folderList.slice().sort((a, b) => {
      //   if (a.name < b.name) return -1;
      //   if (a.name > b.name) return 1;
      //   return 0;
      // });
      return sortedFolderList;
    },

    projectList() {
      const emptyProjectList = [{ list: [] }, { list: [] }, { list: [] },]
      let projectList;
      if (this.filterType === 'folders') return emptyProjectList
      if (this.currentFolderPath.length !== 1) {
        projectList = this.$store.getters["folder/getProjectList"];
      } else {
        projectList = this.$store.getters["project/getProjectList"];
      }
      
      return projectList;
    },

    completedProjectList() {
      return this.projectList[2].list;
    },

    noMoreProjectsToLoad() {
      return this.$store.getters["project/getNoMoreProjectsToLoad"]
    },
    noMoreProjectsToLoadFolder() {
      return this.$store.getters["folder/getNoMoreProjectsToLoad"]
    },
    isInsideFolder() {
      return this.currentFolderPath.length > 1;
    },

    userHasProjects() {
      const projectList = this.$store.getters["project/getProjectList"];
      return projectList[1].list.length > 0 || projectList[2].list.length > 0
    },
    userHasFolders() {
      const folderList = this.$store.getters["folder/getFolderList"];
      return (folderList && folderList.length > 0)
    },
    getCurrentFolderId() {
      return this.currentFolderId;
    },
  }
};
</script>
