<template>
  <nav class="navigation-bar pl-5 pr-5">
    <div class="nav-left">
      <span class="home-icon link-underlined">
        <img src="./../../assets/menu-icons/MENU_home.svg" alt="🏠" class="menu__icon">
      </span>
      <span class="ml-2 link-underlined" @click="goToFolder(0)">{{ $t('home') }}</span>
      <span v-for="(folder, index) in folderPath.slice(1)" :key="index" class="folder">
        <span class="separator"> > </span>
        <span class="folder-name link-underlined" @click="goToFolder(index + 1)">{{ folder.name }}</span>
      </span>
    </div>

    <div class="nav-right" >

      <b-dropdown id="sort-dropdown" no-caret size="sm" variant="none" dropdown v-if="!isInsideFolder">
        <template #button-content>
          <button class="nav-button">
            {{ $t('filter') }}: {{ currentFilter }}
          </button>
        </template>

        <b-dropdown-item @click="showAll">
          {{ $t('page.dashboard.showAll') }}
        </b-dropdown-item>
        <b-dropdown-item @click="filterProjects">
          {{ $t('page.dashboard.showOnlyprojects') }}
        </b-dropdown-item>
        <b-dropdown-item @click="filterFolders">
          {{ $t('page.dashboard.showOnlyFolders') }}
        </b-dropdown-item>
      </b-dropdown>


      <b-dropdown id="sort-dropdown" no-caret size="sm" variant="none" dropdown >
        <template #button-content>
          <button class="nav-button">
            {{ $t('sort') }}{{ currentSortText }}
          </button>
        </template>

        <b-dropdown-item @click="sortContent('alph')">
          {{ $t('name') }} 
        </b-dropdown-item>
        <b-dropdown-item @click="sortContent('dec')">
          {{ $t('newest') }}
        </b-dropdown-item>
        <b-dropdown-item @click="sortContent('asc')">
          {{ $t('oldest') }}
        </b-dropdown-item>
      </b-dropdown>


    </div>
  </nav>
</template>

<script>
export default {
  props: {
    folderPath: {
      type: Array,
      required: true
    },
    isInsideFolder: {
      type: Boolean,
      required: true,
    },

  },
  data() {
    return {
      currentFilter: 'all',
      currentFilterText: this.$t('page.dashboard.filterOptions.all'),
      sort:'dec'
    };
  },
  methods: {
    goToFolder(index) {
      this.$emit('navigateToFolder', this.folderPath.slice(0, index + 1));
    },
    filterFolders() {
      this.currentFilter = 'folders';
      this.currentFilterText = this.$t("page.dashboard.filterOptions.onlyFolders")
      this.$emit('filterContent', 'folders');
    },
    filterProjects() {
      this.currentFilter = 'projects';
      this.currentFilterText = this.$t("page.dashboard.filterOptions.onlyPorjects")
      this.$emit('filterContent', 'projects');
    },
    showAll() {
      this.currentFilter = 'all';
      this.currentFilterText = this.$t("page.dashboard.filterOptions.all")
      this.$emit('filterContent', 'all');
    },
    sortContent(sortBy) {
      this.sort = sortBy;
      this.$emit('sortContent', sortBy);
    },
    
  },
  computed:{
    currentSortText(){
      switch (this.sort) {
        case 'alph': return `: ` + this.$t('alphabetical');
        case 'asc':  return `: ` + this.$t('oldest');
        case 'dec':  return `: ` + this.$t('newest');
        default: return ""
      }
    }
  }
};
</script>

<style scoped>
.navigation-bar {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.nav-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.link-underlined:hover {
  text-decoration: underline;
}

.link-underlined {
  cursor: pointer;
}

.home-icon {
  font-size: 1.5rem;
  align-self: center;
}

.folder {
  display: flex;
  align-items: center;
}

.separator {
  margin: 0 0.5rem;
}

.folder-name {
  font-weight: bold;
}

.menu__icon {
  color: #2d3648;
  width: 16px;
  height: 16px;
}

.nav-right {
  display: flex;
  align-items: center;
}

.nav-button {
  height:42px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  font-size:medium;
}

.nav-button:hover {
  background-color: #ddd;
}

.nav-button.active {
  background-color: lightgray;
}

#sort-dropdown ::v-deep .dropdown-toggle {
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
}

#sort-dropdown ::v-deep .dropdown-toggle:focus {
  outline: none !important;
}
</style>
